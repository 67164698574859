@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Kode Mono", monospace;
  background-color: #111;
  color: #ddd;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  display: none;
}

/*  */
.nav-link {
  transition: 0.1s;
}

.nav-link:hover {
  border-color: #ddd;
}

.hiddenAbout {
  opacity: 0;
  transform: translateY(-75%);
  transition: transform 2s ease-in-out, opacity 2s ease-in-out;
}

.hiddenContact {
  opacity: 0;
  transform: translateY(100%);
  transition: transform 2s ease-in-out, opacity 2s ease-in-out;
}

.showAbout {
  opacity: 1;
  transform: translateY(0);
}

hr {
  width: 0%;
  margin: auto;
  transition: 1s;
}

.hr-full-width {
  width: 100%;
}

.hiddenElementLeft {
  opacity: 0;
  transform: translateX(-100%);
}

.hiddenElementRight {
  opacity: 0;
  transform: translateX(100%);
}

.show {
  opacity: 1;
  transform: translateX(0);
  transition: 0.5s;
}

@keyframes glowingShadow {
  0%,
  100% {
    box-shadow: 0 0 5px 0 rgba(200, 200, 255, 0.7),
      0 0 8px 0 rgba(200, 200, 255, 0.5), 0 0 10px 0 rgba(200, 200, 255, 0.3);
  }
  50% {
    box-shadow: 0 0 10px 5px rgba(200, 200, 255, 0.9),
      0 0 15px 5px rgba(200, 200, 255, 0.7),
      0 0 20px 5px rgba(200, 200, 255, 0.5);
  }
}

.glowing-shadow {
  animation: glowingShadow 2.5s ease-in-out infinite;
}

#contact-link:hover {
  animation: none;
  box-shadow: 0 0 15px 10px rgba(0, 0, 255, 1);
}

/* .project-context,
.project-img {
  transition: all 1s ease-in-out;
}

.project-link:hover + .project-context {
  display: flex;
  opacity: 1;
  width: 50%;
  height: 100%;
} */

/* nav,
ul,
li {
  z-index: 1;
}

nav {
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: #000;
  color: #ddd;
  z-index: 1;
}

nav,
ul,
article,
form,
.context,
.project-context,
.card,
.project-link,
.contact-input,
#title,
#about-section,
#portfolio-container,
#contact-section {
  display: flex;
}

hr {
  width: 100%;
}

h4 {
  margin: 0 10px;
  padding: 0 15px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  border-left: 2px solid white;
  border-right: 2px solid white;
}

ul {
  justify-content: space-between;
  align-items: center;
}



/* .nav-link {
  font-size: 14px;
  text-decoration: none;
  color: #ddd;
}

section {
  height: 100vh;
  width: 100vw;
  background-color: #000;
}

#title {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  z-index: 3;
}

h1 {
  color: #ddd;
  text-align: center;
  background-color: black;
}

#video {
  overflow: hidden;
  position: absolute;
  top: 50px;
  left: 0;
}

#cover {
  height: 90vh;
  width: 100vw;
  background-color: rgba(10, 10, 10, 0.5);
  z-index: 1;
}

#about-section {
  justify-content: space-evenly;
  align-items: center;
  height: 75vh;
}

#profile-img {
  height: 100%;
  width: 50%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #000;
}

.context,
.project-context {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
  width: 50%;
  padding: 30px;
  color: #eee;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
  font-weight: 500;
}

article {
  height: 50%;
  width: 60%;
  background-color: #000;
  border-radius: 10px;
  box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.1);
}

#contact-link {
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  color: #eee;
}

.card {
  height: 20%;
  width: 75%;
  transition: 0.4s;
}

#portfolio-container {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 20px;
}

.project-link {
  width: 100%;
  transition: 0.5s;
}

.card:hover {
  box-shadow: 0 0 50px 10px rgba(255, 255, 255, 0.05);
  transform: scale(1.2);
}

.card:hover .project-context {
  opacity: 1;
}

.project-img {
  height: 100%;
  width: 50%;
  border-radius: 10px;
  object-fit: contain;
}

.project-context {
  opacity: 0;
  transition: 0.4s;
}

#contact-section {
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
}

form {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50%;
  width: 50%;
}

.contact-input {
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
  width: 60%;
}

label,
input {
  width: 50%;
} */
